import firebase from '../firebase';
import history from "App.js"
import { logHost } from './host';
import errorLog from 'errorLog';


const registerHost = (email, password, name) => {
  console.log('registerHost', email)
  let host = { name: name, email: email }
  firebase.auth().createUserWithEmailAndPassword(email, password)
  .then(function(result) {
    console.log('アカウント作成成功')
    let user = firebase.auth().currentUser;
    if (!user) {
      console.error('アカウント作成できたのにuserがnull')
      errorLog('host/create-host-null', host)
      history.push('/admin/login/');
    } 

    console.log('####RegisterPage.handleRegister---doc.set()')
    firebase.firestore().collection("hosts").doc(user.uid).set(host)
    .then(function(docRef) {
      host.uid = user.uid
      console.log("Document written with ID: ", host)
      logHost(host.uid, 'create-host', host)
    })
    .catch(function(error) {
      console.error("Error adding document: ", error)
      errorLog('host/create-host-set', {error:error.code, host:host})
    })
    history.push("/admin/add-room")
    //history.push("/admin/profile")

  }).catch(function(error) {
    if (error.code !== 'auth/email-already-in-use') {
      console.error('アカウント作成失敗', error.code, error.message)
      errorLog('host/create-host-auth', {error:error.code, host:host})
      alert(error.message)
      history.push("/auth/about")
      return
    }

    firebase.auth().signInWithEmailAndPassword(email, password)
    .then(function(result) {
      console.log('Sign-in successful.')
      history.push('/admin/booklist/');
    }).catch(function(error) {
      console.error('サインイン失敗', {error})
      errorLog('host/create-host-sign-in', {error:error.code, host:host})
      alert(error.message)
      history.push("/auth/about")
    })
  })	
}
export default registerHost
