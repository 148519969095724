import React, { useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import AppContext from 'contexts/AppContext'
import { changeRoomProperty } from '../../actions/room'
import { addRoom } from '../../actions/rooms'
import {
  ADD_ROOM_DLG_CONFIRM,
  ADD_ROOM_DLG_ALERT,
  ADD_ROOM_DLG_COMPLETE,
  ADD_ROOM_DLG_ERROR,
  ADD_ROOM_DLG_CLOSE,
} from 'actions'
import history from "App.js"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const styles = {
    customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    }
};
const useStyles = makeStyles(styles);

const AddRoom = () => {
  const { state, dispatch } = useContext(AppContext)
  const classes = useStyles()
  let room = state.room
  
  const handleRegister = () => {
    dispatch({type:ADD_ROOM_DLG_CONFIRM})
  }

  const handleBtn1 = () => {
    switch (room.dlg) {
      case ADD_ROOM_DLG_CONFIRM: //中止
        dispatch({type:ADD_ROOM_DLG_CLOSE})
        break

      case ADD_ROOM_DLG_COMPLETE: //物件一覧へ
        dispatch({type:ADD_ROOM_DLG_CLOSE})
        history.push('/admin/roomlist')
        break

      default:
    }
  }

  const handleBtn2 = () => {
    switch (room.dlg) {
      case ADD_ROOM_DLG_CONFIRM: //登録
        addRoom(state, dispatch)
        break

      case ADD_ROOM_DLG_ALERT: //確認
      case ADD_ROOM_DLG_COMPLETE: //続けて物件を登録する
      case ADD_ROOM_DLG_ERROR: //確認
        dispatch({type:ADD_ROOM_DLG_CLOSE})
        break

      default:
    }
  }
  
  return(
    <GridContainer justify="center">
    <GridItem >
      <Card>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4 className={classes.cardTitle}>物件登録</h4>
            <div>
            　登録番号：行政に報告書を作るときに必要な届出番号になります（重複は許されません）
            </div>
            <div>
            　名称：ゲスト様に表示する物件名称になります
            </div>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>
            <CustomInput
              success={room.numberState === "success"}
              error={room.numberState === "error"}
              labelText="登録番号"
              id="number"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => changeRoomProperty('number', event.target.value, dispatch),
                type: "text",
                autoComplete: "off",
                value: room.number
              }}
            />
            <CustomInput
              success={room.nameState === "success"}
              error={room.nameState === "error"}
              labelText="名称"
              id="name"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => changeRoomProperty('name', event.target.value, dispatch),
                type: "text",
                value: room.name
              }}
            />
            <CustomInput
              success={room.capacityState === "success"}
              error={room.capacityState === "error"}
              labelText="定員"
              id="capacity"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => changeRoomProperty('capacity', event.target.value, dispatch),
                type: "number",
                autoComplete: "off",
                value: room.capacity
              }}
            />
            <Button
              color="rose"
              onClick={handleRegister}
              disabled={room.buttonDisabled}
              className={classes.registerButton}
            >
              登録
            </Button>
          </form>
        </CardBody>
      </Card>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalSmall
        }}
        open={room.dlg}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleBtn1()}
        aria-labelledby="small-modal-slide-title"
        aria-describedby="small-modal-slide-description"
        >
        <DialogTitle
          id="small-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => handleBtn1()}
          >
            <Close className={classes.modalClose} />
          </Button>
        </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={
              classes.modalBody + " " + classes.modalSmallBody
            }
          >
            <h5>{room.dlgMsg}</h5>
          </DialogContent>
          <DialogActions
            className={
              classes.modalFooter + " " + classes.modalFooterCenter
            }
          >
            <Button
              onClick={() => handleBtn1()}
              color="transparent"
              className={classes.modalSmallFooterFirstButton}
            >
              {room.dlgBtn1txt}
            </Button>
            <Button
              onClick={() => handleBtn2()}
              color="success"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              {room.dlgBtn2txt}
            </Button>
          </DialogActions>
        </Dialog>
     </GridItem>
    </GridContainer>
  )
}
export default AddRoom