import React, { useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import BookState from './BookState.js'
import AppContext from 'contexts/AppContext'
import { changeBookProperty, setBookStatus } from '../../actions/book'
import { CLEAR_BOOK } from '../../actions'
import GuestBook from './GuestBook'
import { deleteBook } from '../../actions/books'

const styles = {
    customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    }
};
const useStyles = makeStyles(styles);


//Guest用のURLをクリップボードにコピーする
const copyUrlToCLipbord = () => {
  // 目的の文字列が入っている p要素を取得します。
  const element = document.querySelector('#guest-url');
  // seletionオブジェクトを取得します。
  const selection = window.getSelection();
  // rangeオブジェクトを生成します。
  const range = document.createRange();
  // rangeオブジェクトに p要素を与えます。
  range.selectNodeContents(element);
  // 一旦、selectionオブジェクトの持つ rangeオブジェクトを削除します。
  selection.removeAllRanges();
  // 改めて先程生成した rangeオブジェクトを selectionオブジェクトに追加します。
  selection.addRange(range);
  console.log('選択された文字列: ', selection.toString());
  // クリップボードにコピーします。
  const succeeded = document.execCommand('copy');
  if (succeeded) {
      // コピーに成功した場合の処理です。
      console.log('コピーが成功しました！');
  } else {
      // コピーに失敗した場合の処理です。
      console.log('コピーが失敗しました!');
  }
  // selectionオブジェクトの持つrangeオブジェクトを全て削除しておきます。
  selection.removeAllRanges();	
}

export default function BookDetail(){
  const { state, dispatch } = useContext(AppContext)
  const classes = useStyles();

  function handleDelete(e){
    let book = state.book
    const result = window.confirm(`${book.roomName}の${book.guestName}様からの予約(${book.checkin}〜${book.checkout})を削除します`)
    if (result) {
      deleteBook(state, dispatch)
    }
  }

  //宿帳承認待ちと承認済みの時のみ表示する
  const buttonAndGuestBook = () => {
    switch(state.book.status) {
      case 'applied':
        return (
          <div>
            <Button color="rose" className={classes.registerButton}
              onClick={() => dispatch({type: CLEAR_BOOK})} 
            >一覧に戻る</Button>
          
            <Button color="success"
              onClick={() => setBookStatus(state, 'approved', dispatch)}
              className={classes.registerButton}
            >宿泊者名簿承認</Button>
          
            <GuestBook />
          </div>
        )
      case 'approved':
        return(
          <div>
            <Button color="rose" className={classes.cancelButton}
              onClick={() => dispatch({type: CLEAR_BOOK})} 
            >一覧に戻る</Button>
          
            <Button color="success"
              className={classes.registerButton}
              disabled
            >宿泊者名簿承認済</Button>
            <GuestBook />
          </div>
        )
      default:
        return (
          <div>
            <Button color="rose" className={classes.registerButton}
              onClick={() => dispatch({type: CLEAR_BOOK})} 
            >一覧に戻る</Button>
            <Button color="danger"
              onClick={handleDelete}
              className={classes.registerButton}
            >予約削除</Button>
          </div>
        )
    }  
  }

  if (state.book.status.length < 1) {
    return  null
  }
  console.log('BookDetail', state)
  return(
    <GridItem xs={12} >
      <Card>
        <CardHeader>
          <h4 className={classes.cardTitle}>予約の詳細</h4>
          <p>物件名：{state.book.selectName}</p>
          <p>ゲスト名：{state.book.guestName}</p>
          <p>状態：<BookState status={state.book.status} /></p>
        </CardHeader>
        <CardBody>
          <Paper className={classes.root}>
            <Typography component="p">
                ゲストに通知するアドレス
                <Button size="sm" onClick={copyUrlToCLipbord}>アドレスをコピー</Button>
            </Typography>
              <Typography id="guest-url" variant="body1" >
                {state.book.guestURL}
              </Typography>
          </Paper>

          <form>

            <CustomInput
              labelText="メールアドレス"
              success={state.book.emailState === "success"}
              error={state.book.emailState === "error"}
              id="guestEmail"
              formControlProps={{
                fullWidth: false
              }}
              value={state.book.email}
              inputProps={{
                onChange: event => changeBookProperty('email', event.target.value, dispatch),
                defaultValue: state.book.email,
                type: "email"
              }}
            />
            <CustomInput
              success={state.book.numState === "success"}
              error={state.book.numState === "error"}
              labelText="人数"
              id="numOfGguest"
              formControlProps={{
                fullWidth: false
              }}
              inputProps={{
                onChange: event => changeBookProperty('numOfGuest', event.target.value, dispatch),
                defaultValue: state.book.numOfGuest,
                type: "number",
                autoComplete: "off"
              }}
            />

            <TextField
              id="checkin"
              label="チェックイン"
              type="date"
              defaultValue={state.book.checkin}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="checkout"
              label="チェックアウト"
              type="date"
              defaultValue={state.book.checkout}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />

            {buttonAndGuestBook()}

          </form>
        </CardBody>
      </Card>
    </GridItem>
  )
}
