import {
  SET_ROOM_VALUE,
  CLEAR_ROOM,
  ADD_ROOM_DLG_CONFIRM,
  ADD_ROOM_DLG_ALERT,
  ADD_ROOM_DLG_PENDING,
  ADD_ROOM_DLG_COMPLETE,
  ADD_ROOM_DLG_ERROR,
  ADD_ROOM_DLG_CLOSE,
} from '../actions'


export const initialState = {
  name: '',
  nameState: 'error',
  number: '',
  numberState: 'error',
  capacity: '',
  capacityState: 'error',
  buttonDisabled: true,
  dlg: false,
  dlgBtn1txt: '',
  dlgBtn2txt: '',
}


const room = (state=initialState, action) => {
  switch(action.type) {
    case CLEAR_ROOM:
      return  initialState

    case SET_ROOM_VALUE:
      {
        let newState=JSON.parse(JSON.stringify(state))
        newState[action.name]=action.value
        setRoomDataValid(newState)
        return  newState
      }

    case ADD_ROOM_DLG_CONFIRM:
      {
        let newState=JSON.parse(JSON.stringify(state))
        newState.dlg = ADD_ROOM_DLG_CONFIRM
        newState.dlgMsg = '登録１物件毎に利用料が発生します。登録しますか？'
        newState.dlgBtn1txt = '中止'
        newState.dlgBtn2txt = '登録'
        return newState
      }

    case ADD_ROOM_DLG_ALERT:
      {
        let newState=JSON.parse(JSON.stringify(state))
        newState.dlg = ADD_ROOM_DLG_ALERT
        newState.dlgMsg = '同じ登録番号の部屋が既ににあります、登録番号の重複は許されません'
        newState.dlgBtn1txt = ''
        newState.dlgBtn2txt = '確認'
        return newState
      }

    case ADD_ROOM_DLG_PENDING:
      {
        let newState=JSON.parse(JSON.stringify(state))
        newState.dlg = ADD_ROOM_DLG_PENDING
        newState.dlgMsg = '物件登録中'
        newState.dlgBtn1txt = ''
        newState.dlgBtn2txt = ''
        return newState
      }

     case ADD_ROOM_DLG_COMPLETE:
       {
        let newState=initialState
        newState.dlg = ADD_ROOM_DLG_COMPLETE
        newState.dlgMsg = '物件登録が完了しました'
        newState.dlgBtn1txt = '物件一覧へ'
        newState.dlgBtn2txt = '続けて登録'
        return newState
       }

    case ADD_ROOM_DLG_ERROR:
      {
        let newState=JSON.parse(JSON.stringify(state))
        newState.dlg = ADD_ROOM_DLG_ERROR
        newState.dlgMsg = '通信エラー'
        newState.dlgBtn1txt = ''
        newState.dlgBtn2txt = '確認'
        return newState
      }

    case ADD_ROOM_DLG_CLOSE:
      {
        let newState=JSON.parse(JSON.stringify(state))
        newState.dlg = false
        return newState
      }
    
    default:
      return state
  }
}
export default room


const setRoomDataValid = (state) => {
  if (state.nameState === 'error') { 
    state.buttonDisabled = true
    return
  }
  if (state.numberState === 'error') { 
    state.buttonDisabled = true
    return
  }
  if (state.capacityState === 'error') { 
    state.buttonDisabled = true
    return
  }
  state.buttonDisabled = false
}