import BookList from "views/BookList/BookList.js"
import AddBook from "views/BookList/AddBook.js"
import RoomList from "views/RoomList/RoomList.js"
import AddRoom from "views/RoomList/AddRoom.js"
import HostConfig from "views/config/HostConfig.js"
import LoginPage from "views/LoginPage.js"
import AboutPage from "views/AboutPage.js"
import RegisterPage from "views/RegisterPage.js"

import ListIcon from "@material-ui/icons/List";


var dashRoutes = [
  {
    path: "/booklist",
    name: "予約リスト",
    rtlName: "لوحة القيادة",
    icon: ListIcon,
    component: BookList,
    layout: "/admin"
  },
  {
    path: "/roomlist",
    name: "物件リスト",
    rtlName: "لوحة القيادة",
    icon: ListIcon,
    component: RoomList,
    layout: "/admin"
  },
  {
    path: "/add-book",
    name: "予約登録",
    rtlName: "تسجيل",
    mini: "AB",
    rtlMini: "صع",
    component: AddBook,
    layout: "/admin"
  },
  {
    path: "/add-room",
    name: "物件登録",
    rtlName: "تسجيل",
    mini: "AR",
    rtlMini: "صع",
    component: AddRoom,
    layout: "/admin"
  },
  {
    path: "/config",
    name: "ホスト設定",
    rtlName: "لوحة القيادة",
    icon: "content_paste",
    component: HostConfig,
    layout: "/admin"
  },
  {
    path: "/login-page",
    name: "ログイン",
    rtlName: "هعذاتسجيل الدخول",
    mini: "L",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth"
  },
  {
    path: "/register-page",
    name: "ユーザー登録",
    rtlName: "تسجيل",
    mini: "R",
    rtlMini: "صع",
    component: RegisterPage,
    layout: "/auth"
  },
  {
    path: "/about",
    name: "サイトの説明",
    rtlName: "تسجيل",
    mini: "A",
    rtlMini: "صع",
    component: AboutPage,
    layout: "/auth"
  },
];
export default dashRoutes;
