import firebase from '../firebase';
import errorLog from '../errorLog'

//ランダム文字列生成
const randomText = (length) => {
  const charSet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
      result += charSet[Math.floor(Math.random() * charSet.length)];
  }
  return result;
};

function generateGuest(guest){
  //console.log('generateGuest', guest)
  return new Promise((resolve, reject) => {
    //DBにguest documentを作成
    var user = firebase.auth().currentUser
    if (user) {
      //IDを生成
      var guestID = randomText(8);
      //console.log('guestid=', guestID)
      //ちょっと問題あるけど2回ID作って当たったらエラーにする
      firebase.firestore().collection("guests").doc(guestID).get()
      .then(function(doc) {
        if (doc.exist) {
          console.log("おなじIDのDocumentが存在する", doc.data())
          guestID = randomText(8); //再度ランダム文字列を作成

          firebase.firestore().collection("guests").doc(guestID).get()
          .then(function(doc) {
            if (doc.exist) {
              console.log("おなじIDのDocumentが存在する", doc.data())
              reject('doc creation failed') //作成失敗
            }
          })
          .catch(function(error) {
              console.error("Error getting document: ", error)
              reject(error) //読み込み失敗
          })
        }
      })
      .catch(function(error) {
          console.error("Error getting document: ", error)
          reject(null); //読み込み失敗
      })
    
      console.log('####generateGuest---doc.set()', guest)
      firebase.firestore().collection("guests").doc(guestID).set(guest)
      .then(function(doc) {
        console.log("guest作成成功", guestID)
        resolve(guestID)
      })
      .catch(function(error) {
        console.error("guest書き込み失敗: ", error)
        errorLog('host/create-guest', {error:error.code, guest:guest})
        reject(null)
      })
    }
  })
}

export default generateGuest