import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import history from "App.js"

const useStyles = makeStyles(styles);

export default function AboutPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
	function handleLogin(e){
		history.push("/auth/login-page")
	}
	function handleRegister(e) {
		history.push("/auth/register-page")
	}
	const classes = useStyles();
	return(
		<div className={classes.container}>
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={10}>
					<form>
						<Card className={classes[cardAnimaton]}>
							<CardHeader
								className={`${classes.cardHeader} ${classes.textCenter}`}
								color="primary"
							>
								<h4 className={classes.cardTitle}>Cloud Registrationについて</h4>
							</CardHeader>
							<CardBody>
								Cloud Registrationは、民泊事業者に求められている「宿泊名簿の整備」をサポートするシステムです<div/>
								宿泊者にインターネット上で「宿泊者名簿」に記入してもらうことができます
							</CardBody>
							<CardFooter className={classes.justifyContentCenter}>
								<Button color="primary" round 
										onClick = {handleLogin}
										id = "loginButton"
										className={classes.marginRight}>
									Log in
								</Button>
								<Button color="primary" round 
									onClick = {handleRegister}>
									Register
								</Button>
							</CardFooter>
						</Card>
					</form>
				</GridItem>
			</GridContainer>
		</div>
	);
}