import firebase from '../firebase'
import { SET_BOOK, SET_BOOK_VALUE, } from '../actions'
import errorLog from '../errorLog'

export const changeBookProperty = (name, value, dispatch) => {
  switch (name) {
    case 'guestName':
      if (verifyLength(value, 1)) {
        setState('nameState', 'success', dispatch)
      } else {
        setState('nameState', 'error', dispatch)
      }
      break;
    case 'email':
      if (value.length===0) {
        setState('emailState', 'success', dispatch)
      } else {
        if (verifyEmail(value)) {
          setState('emailState', 'success', dispatch)
        } else {
          setState('emailState', 'error', dispatch)
        }
      }
      break;
    case 'numOfGuest':
      if (verifyNumOfGuest(value)) {
        setState('numState', 'success', dispatch)
      } else {
        setState('numState', 'error', dispatch)
      }
      break;
    default:
      break;
  }
  setState(name, value, dispatch)
}

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true
  }
  return false
}

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}

// 年齢を確認数値で、0から100までの範囲ならtrue
const verifyNumOfGuest = value => {
  var numberRex = new RegExp("^[0-9]+$")
  if (numberRex.test(value)) {
    return Number(value) >= 1 && Number(value) <= 50
  }
  return false;
}

const setState = (name, value, dispatch) => {
  dispatch({
    type: SET_BOOK_VALUE,
    name: name,
    value: value
  })
}


export const setBook = (book, dispatch) => {
  //console.log('setBook', book)
  dispatch({ type: SET_BOOK, book: book })
  changeBookProperty('guestName', book.guestName, dispatch)
  changeBookProperty('email', book.email, dispatch)
  changeBookProperty('numOfGuest', book.numOfGuest, dispatch)
  setState('selectName', book.roomNumber+'：'+book.roomName, dispatch)
  setState('guestURL', 'http://guest.i-sis.net/register?'+book.guestID, dispatch)
}



export const setBookStatus = (state, status, dispatch) => {
  //console.log('setBookStatus', state, status)
  var newStatus = { status: status }
  var mergeOption = { merge: true }
  var db = firebase.firestore()
  var batch = db.batch()
  var bookRef = db.collection('hosts').doc(state.host.uid)
                  .collection('books').doc(state.book.id)
  var guestRef = db.collection("guests").doc(state.book.guestID)
  batch.set(bookRef, newStatus, mergeOption)
  batch.set(guestRef, newStatus, mergeOption)
  batch.commit().then(function() {
    console.log('####status書き込み成功')
    logBook(state.host.uid, state.book.id, 'host/new-status', newStatus)
    dispatch({
      type: SET_BOOK_VALUE,
      name: 'status',
      value: status
    })
  })
  .catch(function(error) {
    console.error('####status書き込み失敗', error)
    errorLog('host/write-status', {error:error.code, newStatus})
  })
}


export const logBook = (hostid, bookid, event, content) => {
  //console.log('logBook', hostid, bookid, event, content)
  let db = firebase.firestore()
  var log = {
    timestamp: firebase.firestore.Timestamp.now(),
    event: event,
    content: content
  }
  db.collection('hosts').doc(hostid).collection('books').doc(bookid)
    .collection('logs').add(log)
  .then(function() {
  })
  .catch(function(error) {
    console.error('####book, log書き込み失敗', error, hostid, bookid, content)
  })
}