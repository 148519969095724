import firebase from '../firebase'
import {
  CLEAR_BOOK,
  SET_BOOKS,
  CREATE_BOOK,
  CLEAR_BOOKS,
  DELETE_BOOK,
} from '../actions'
import generateGuest from './generateGuest'
import history from "App.js"
import { setBook } from './book'
import { logHost } from '../actions/host'
import { logBook } from '../actions/book'
import errorLog from '../errorLog'



export const readBooks = (dispatch) => {
  console.log('readBooks')
  let host = firebase.auth().currentUser
  if (!host) {
    dispatch({type: CLEAR_BOOKS })
    return
  }
 
  firebase.firestore().collection("hosts").doc(host.uid)
  .collection("books").get()
  .then(function(querySnapshot) {
    console.log('####bookList---collection.get')
    if (querySnapshot.size === 0) {
      console.log('booksコレクション内にdocがゼロ')
      dispatch({type: CLEAR_BOOKS })
    } else {
      var books = []
      querySnapshot.forEach((doc) => {
        let book = doc.data()
        book.id = doc.id
        books.push(book)
      })
      dispatch({type: SET_BOOKS, books: books })
    }
  })
  .catch(function(error){
    console.log('booksコレクション読み取り失敗: ', error )
    errorLog('host/read-books', host.uid)
  })
}


export const addBook = (host, room, book, dispatch) => {
  let bookID = room.number + '-' +('0000' + room.nextBookSerial).slice(-4)
  var guest = {
    guestName: book.guestName,
    email: book.email,
    bookID: bookID,
    hostID: host.uid,
    roomName: book.roomName,
    status: "initial",
  }
  generateGuest(guest)
  .then(function(guestID){
    if (!guestID) //guest作成に失敗したら
      return      //終了
    book.guestID = guestID
    console.log('guest作成に成功して、これからbooksに予約作成', book)
    let db = firebase.firestore()
    var batch = db.batch()
    let bookRef = db.collection("hosts").doc(host.uid).collection("books").doc(bookID)
    batch.set(bookRef, book)
    let roomRef = db.collection("hosts").doc(host.uid).collection("rooms").doc(room.number)
    batch.update(roomRef, { nextBookSerial: room.nextBookSerial + 1 })
    batch.commit().then(function() {
      console.log('####addBook成功', book)
      logHost(host.uid, 'create-book', book)
      logBook(host.uid, bookID, 'host/create-book', book)
      book.id = bookID
      dispatch({ type: CREATE_BOOK, book: book })
      setBook(book, dispatch)
      history.push('/admin/booklist/')
    })
    .catch(function(error) {
      console.error('####addBook失敗', error)
      errorLog('host/create-book', {error:error.code, host:host.uid, book:book})
    })
  })
  .catch(function(error) {
    console.error('####generateGuestの例外', error)
  })
}


export const deleteBook = (state, dispatch) => {
  //console.log('deleteBook', state.book)
  let db = firebase.firestore()
  var batch = db.batch()
  let bookRef = db.collection("hosts").doc(state.host.uid).collection("books").doc(state.book.id)
  batch.delete(bookRef)
  let guestRef = db.collection("guests").doc(state.book.guestID)
  batch.delete(guestRef)
  batch.commit().then(function() { //DBから削除して
    console.log('####deleteDocumet成功', state.book)
    logHost(state.host.uid, 'delete-book', state.book.id)
    dispatch({type: DELETE_BOOK, id: state.book.id }) //state.booksから削除して
    dispatch({type: CLEAR_BOOK })       //state.bookをクリアする
  })
  .catch(function(error) {
    console.error('####deleteRoom失敗', error)
    errorLog('host/delete-book', {error:error.code, host:state.host.uid, book:state.book.id})
  })
}