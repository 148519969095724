import React, { useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import AppContext from 'contexts/AppContext'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';
import history from "App.js"
import BookDetail from "./BookDetail"
import { bookStatus } from './BookState'
import { setBook } from 'actions/book'

const styles = {
  customCardContentClass: {
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  tableRow: {
    clickable: 'true',
  },
  bookState: {
  },
  registerd: {
    background: '#87cefa'
  },
  verified: {
    background: '#f5deb3'
  },
  agreed: {
    background: '#f5deb3'
  },
  written: {
    background: '#f5deb3'
  },
  applied: {
    background: '#f5deb3'
  },
  approved: {
    background: '#98fb98'
  },
}
const useStyles = makeStyles(styles);


export default function BookList() {
  const { state, dispatch } = useContext(AppContext)
  const classes = useStyles();
  const addBtnstyle = {
    margin: 0,
    top: 'auto',
    right: 24,
    bottom: 24,
    left: 'auto',
    position: 'fixed',
  };
  
  function handleAdd(e){
    history.push("/admin/add-book")
  }
  
  const handleClick = (event, id) => {
    setBook(state.books[id], dispatch)
  }

  let books = state.books

  if (state.book.status.length > 0)
    return (
      <>
        <BookDetail />
      </>
    )
  else
    return (
      <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="bookList"
          >
            <TableHead>
              <TableRow>
                <TableCell>状態</TableCell>
                <TableCell>部屋名</TableCell>
                <TableCell>ゲスト</TableCell> 
                <TableCell>ﾁｪｯｸｲﾝ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {books.map((book, index) => {
              const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, index)}
                    role="checkbox"
                    tabIndex={-1}
                    key={book.id}
                  >
                    <TableCell component="th" id={labelId} scope="row" >
                      <div className={classes[book.status]}>
                        {bookStatus(book.status).text}
                      </div>
                    </TableCell>
                    <TableCell align="left">{book.roomName}</TableCell>
                    <TableCell align="left">{book.guestName}</TableCell>
                    <TableCell align="left">{book.checkin}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
      <Button justIcon round 
        color="primary" 
        style={addBtnstyle}
        onClick = {handleAdd}
        >
        <AddIcon />
      </Button>
    </div>
  )
}


