import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import registerHost from '../actions/register'

const useStyles = makeStyles(styles);

export default function RegisterPage() {
  const [registerName, setregisterName] = React.useState("");
  const [registerNameState, setregisterNameState] = React.useState("error");
  const [registerEmail, setregisterEmail] = React.useState("");
  const [registerEmailState, setregisterEmailState] = React.useState("error");
  const [registerPassword, setregisterPassword] = React.useState("");
  const [registerPasswordState, setregisterPasswordState] = React.useState("error");
  const [registerConfirmPassword, setregisterConfirmPassword] = React.useState("");
  const [registerConfirmPasswordState, setregisterConfirmPasswordState] = React.useState("error");
  const [registerCheckbox, setregisterCheckbox] = React.useState(false);
  const [registerCheckboxState, setregisterCheckboxState] = React.useState("error");
  // function that returns true if value is email, false otherwise
  const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

	function handleRegister(e){
    if (registerNameState === "error") { return; }
    if (registerEmailState === "error") { return; }
    if (registerPasswordState === "error") { return; }
    if (registerConfirmPassword === "error") { return; }
    if (registerCheckbox === false) { return; }
    registerHost(registerEmail, registerPassword, registerName)
  }


  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>ユーザー登録</h4>
            </CardHeader>
            <CardBody>
              <form>
                <CustomInput
                  success={registerNameState === "success"}
                  error={registerNameState === "error"}
                  labelText="お名前 *"
                  id="registername"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      if (verifyLength(event.target.value, 1)) {
                        setregisterNameState("success");
                      } else {
                        setregisterNameState("error");
                      }
                      setregisterName(event.target.value);
                    },
                    type: "text"
                  }}
                />
                <CustomInput
                  success={registerEmailState === "success"}
                  error={registerEmailState === "error"}
                  labelText="メールアドレス *"
                  id="registeremail"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      if (verifyEmail(event.target.value)) {
                        setregisterEmailState("success");
                      } else {
                        setregisterEmailState("error");
                      }
                      setregisterEmail(event.target.value);
                    },
                    type: "email"
                  }}
                />
                <CustomInput
                  success={registerPasswordState === "success"}
                  error={registerPasswordState === "error"}
                  labelText="パスワード *"
                  id="registerpassword"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      if (verifyLength(event.target.value, 6)) {
                        setregisterPasswordState("success");
                      } else {
                        setregisterPasswordState("error");
                      }
                      setregisterPassword(event.target.value);
                    },
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <CustomInput
                  success={registerConfirmPasswordState === "success"}
                  error={registerConfirmPasswordState === "error"}
                  labelText="パスワードの確認 *"
                  id="registerconfirmpassword"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      if (registerPassword === event.target.value) {
                        setregisterConfirmPasswordState("success");
                      } else {
                        setregisterConfirmPasswordState("error");
                      }
                      setregisterConfirmPassword(event.target.value);
                    },
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <div className={classes.formCategory}>
                  <small>*</small> 必須項目
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={event => {
                        if (event.target.checked) {
                          setregisterCheckboxState("success");
                        } else {
                          setregisterCheckboxState("error");
                        }
                        setregisterCheckbox(event.target.checked);
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label:
                      classes.label +
                      (registerCheckboxState === "error"
                        ? " " + classes.labelError
                        : "")
                  }}
                  label={
                    <span>
                      I agree to the{" "}
                      <a href="#pablo">terms and conditions</a>.
                    </span>
                  }
                />
                <div className={classes.center}>
                  <Button round color="primary"
                      onClick = {handleRegister}
                      className={classes.registerButton}
                      >
                    登録する
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
