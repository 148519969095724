import React, { useContext, useState } from 'react';
import AppContext from '../../contexts/AppContext'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import GuestTab from './GuestMember'

import {
  cardTitle,
} from "assets/jss/material-dashboard-pro-react.js";


const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  marginTop30: {
    marginTop: "30px"
  },
  root: {
    flexGrow: 1,
    width: '100%',
    //ßbackgroundColor: theme.palette.background.paper,
  },
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(styles);

export default function GuestBook() {
  const { state }  = useContext(AppContext)
  const [value, setValue] = useState(0)
  const classes = useStyles()

  //タブの変更
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  let tabLabel = []
  if (state.book.numOfGuest) {
    tabLabel.push('代表者')
    for (var i=1; i<state.book.numOfGuest; i++)
      tabLabel.push('同行者'+String(i))
  }

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} >
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitleWhite}>宿泊者名簿</h4>
                <h4 className={classes.cardCategoryWhite}>
                  内容を確認の上、承認してください
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  {tabLabel.map((label, index) => {
                    return (
                      <Tab key={label} label={label} {...a11yProps(index)} />                      
                    )
                  })}
                </Tabs>
              </AppBar>
              {tabLabel.map((label, index) => {
                return (
                  <GuestTab  key={label} value={value} index={index} />
                )
              })}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}