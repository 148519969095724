export const CLEAR_HOST = 'CLEAR_HOST'
export const SET_HOST = 'SET_HOST'

export const SET_ROOM_VALUE = 'SET_ROOM_VALUE'
export const CLEAR_ROOM = 'CLEAR_ROOM'
export const ADD_ROOM_DLG_CONFIRM  = 'ADD_ROOM_DLG_CONFIRM'
export const ADD_ROOM_DLG_PENDING  = 'ADD_ROOM_DLG_PENDING'
export const ADD_ROOM_DLG_COMPLETE = 'ADD_ROOM_DLG_COMPLETE'
export const ADD_ROOM_DLG_ALERT = 'ADD_ROOM_DLG_ALERT'
export const ADD_ROOM_DLG_ERROR = 'ADD_ROOM_DLG_ERROR'
export const ADD_ROOM_DLG_CLOSE = 'ADD_ROOM_DLG_CLOSE'

export const SET_ROOMS = 'SET_ROOMS'
export const CREATE_ROOM = 'CREATE_ROOM'
export const DELETE_ROOM = 'DELETE_ROOM'
export const CLEAR_ROOMS = 'CLEAR_ROOMS'

export const CLEAR_BOOK = 'CLEAR_BOOK'
export const SET_BOOK = 'SET_BOOK'
export const SET_BOOK_VALUE = 'SET_BOOK_VALUE'

export const SET_BOOKS = 'SET_BOOKS'
export const CREATE_BOOK = 'CREATE_BOOK'
export const DELETE_BOOK = 'DELETE_BOOK'
export const CLEAR_BOOKS = 'CLEAR_BOOKS'