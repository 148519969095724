import {
  SET_ROOMS,
  CREATE_ROOM,
  DELETE_ROOM,
  CLEAR_ROOMS,
} from '../actions'

const rooms = (state = [], action) => {
  switch(action.type) {
    case SET_ROOMS:
      return  action.rooms
    case CREATE_ROOM:
      return [...state, action.room]
    case DELETE_ROOM:
      return state.filter(event => event.number !== action.number)
    case CLEAR_ROOMS:
      return []
    default:
      return state
  }
}

export default rooms