import firebase from '../firebase'
import {
  SET_ROOMS,
  CREATE_ROOM,
  CLEAR_ROOMS,
  DELETE_ROOM,
  ADD_ROOM_DLG_ALERT,
  ADD_ROOM_DLG_PENDING,
  ADD_ROOM_DLG_COMPLETE,
  ADD_ROOM_DLG_ERROR,
} from '../actions'
import errorLog from 'errorLog'
import { logHost } from './host'


export const readRooms = (dispatch) => {
  console.log('readRooms')
  let host = firebase.auth().currentUser
  if (!host) {
    dispatch({type: CLEAR_ROOMS })
    return
  }
 
  firebase.firestore().collection("hosts").doc(host.uid)
  .collection("rooms").get()
  .then(function(querySnapshot) {
    console.log('####RoomList---collection.get')
    if (querySnapshot.size === 0) {
      console.log('roomsコレクション内にdocがゼロ')
      dispatch({type: CLEAR_ROOMS })
    } else {
      var rooms = []
      querySnapshot.forEach((doc) => {
        let room = doc.data()
        room.number = doc.id
        rooms.push(room)
      })
      dispatch({type: SET_ROOMS, rooms: rooms })
    }
  })
  .catch(function(error){
    console.log('roomsコレクション読み取り失敗: ', error )
    errorLog('host/read-rooms', host.uid)
  })
}


export const addRoom = (state, dispatch) => {
  console.log('addRoom', state.host, state.room)
  var room = {
    name: state.room.name,
    capacity: Number(state.room.capacity),
    nextBookSerial: 1
  }
  firebase.firestore().collection("hosts").doc(state.host.uid)
  .collection("rooms").doc(state.room.number).get()
  .then(function(doc) {
    if (doc.exists) {
      dispatch({type: ADD_ROOM_DLG_ALERT })
      return
    }
    dispatch({type: ADD_ROOM_DLG_PENDING })
    firebase.firestore().collection("hosts").doc(state.host.uid)
    .collection("rooms").doc(state.room.number).set(room)
    .then(function() {
      console.log('####addRoom成功')
      room.number = state.room.number
      logHost(state.host.uid, 'create-room', room)
      dispatch({type: CREATE_ROOM, room: room })
      dispatch({type: ADD_ROOM_DLG_COMPLETE })
    })
    .catch(function(error) {
      console.error('####addRoom失敗', error)
      errorLog('host/add-room', {error:error.code, room:room})
      dispatch({type: ADD_ROOM_DLG_ERROR })
    })
  })
  .catch(function(error){
    console.log('docのクエリがエラーする: ', error )
    errorLog('host/query-rooms', {error:error.code, room:room})
    dispatch({type: ADD_ROOM_DLG_ERROR })
  })
}

export const deleteRoom = (state, roomNumber, dispatch) => {
  firebase.firestore().collection("hosts").doc(state.host.uid)
  .collection("rooms").doc(roomNumber).delete()
  .then(function(doc) {
    console.log('####deleteDocumet成功')
    logHost(state.host.uid, 'delete-room', roomNumber)
    dispatch({type: DELETE_ROOM, number: roomNumber })
  })
  .catch(function(error) {
    console.error('####deleteRoom失敗', error)
    errorLog('host/delete-room', {error:error.code, host:state.host.uid, room:roomNumber})
  })
}