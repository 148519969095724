import React, { useContext } from 'react';
import AppContext from '../../contexts/AppContext'

// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

/* 
const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};
const useStyles = makeStyles(style)
*/

const GuestMember = (props) => {
  const { state }  = useContext(AppContext)
  //const classes = useStyles()

  if (props.index !== props.value)
    return null;

console.log('GuestMember', state)

  return (
    <GridContainer >
      <GridItem xs={12} sm={4}>
        <CustomInput
          labelText={<span>名</span>}
          id="firstname"
          formControlProps={{fullWidth: true}}
          disabled
          inputProps={{
            defaultValue: state.book.firstname[props.index]
          }}
        />
        <CustomInput
          labelText={<span>姓</span>}
          id="lastname"
          formControlProps={{fullWidth: true}}
          disabled
          inputProps={{
            defaultValue: state.book.lastname[props.index]
          }}
        />
        <CustomInput
          labelText={<span>年齢</span>}
          id="age"
          formControlProps={{fullWidth: true}}
          disabled
          inputProps={{
            defaultValue: state.book.age[props.index]
          }}
        />
        <CustomInput
          labelText={<span>職業</span> }
          id="ocupation"
          formControlProps={{fullWidth: true}}
          disabled
          inputProps={{
            defaultValue: state.book.ocupation[props.index]
          }}
        />
        <CustomInput
          labelText={<span>国籍</span> }
          id="nationality"
          formControlProps={{fullWidth: true}}
          disabled
          inputProps={{
            defaultValue: state.book.nationality[props.index]
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={8}>
        <legend>身分証明書</legend>
        <img src={state.book.idPhoto[props.index]} width='100%' alt='身分証明書写真' />
        <legend>顔写真</legend>
        <img src={state.book.facePhoto[props.index]} width='100%' alt='顔写真' />
      </GridItem>
    </GridContainer>
  )
}

export default GuestMember