import React from "react";
import Button from "components/CustomButtons/Button.js";

export default function BookState(props){
  var _color, _text
  switch (props.status)
  {
    case 'initial':
      _color='info'
      _text='　　　未登録　　　'
      break
    case 'registerd':
      _color='warning'
      _text='　メール確認待ち　'
        break
    case 'verified':
      _color='warning'
      _text='宿泊約款確認待ち　'
      break
    case 'agreed':
      _color='warning'
      _text='宿泊者名簿記入待ち'
      break
    case 'written':
      _color='rose'
      _text='　写真登録待ち　　'
      break
    case 'applied':
      _color='rose'
      _text='宿泊者名簿承認待ち'
      break
    case 'approved':
      _color='success'
      _text='宿泊者名簿承認済　'
      break
    default:
      _color='danger'
      _text = props.status
  }

	return(
    <Button size="sm" disabled color={_color}>{_text}</Button>
  )
}


export const bookStatus = (status) => {
  var attr = {}
  switch (status)
  {
    case 'initial':
      attr.color=''
      attr.text='未登録'
      break
    case 'registerd':
      attr.color='warning'
      attr.text='メール確認待ち'
        break
    case 'verified':
      attr.color='warning'
      attr.text='宿泊約款確認待ち'
      break
    case 'agreed':
      attr.color='warning'
      attr.text='宿泊者名簿記入待ち'
      break
    case 'written':
      attr.color='warning'
      attr.text='写真登録待ち'
      break
    case 'applied':
      attr.color='danger'
      attr.text='宿泊者名簿承認待ち'
      break
    case 'approved':
      attr.color='success'
      attr.text='宿泊者名簿承認済'
      break
    default:
      attr.color='danger'
      attr.text = status
  }
  return  attr
}