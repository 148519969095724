import {
  SET_BOOK,
  SET_BOOK_VALUE,
  CLEAR_BOOK,
} from '../actions'

export const initialState = {
  status: '',
  name: '',
  email: '',
  numOfGuest: '',
  guestUrl: '',
  nameState: 'error',
  numState: 'error',
  selectName: '',

  firstname: [],
  firstnameState: [],
  lastname: [],
  lastnameState: [],
  age: [],
  ageState: [],
  ocupation: [],
  ocupationState: [],
  nationality: [],
  nationalityState: [],
}

const book = (state = initialState, action) => {
  switch(action.type) {
    case SET_BOOK:
      return action.book
    case SET_BOOK_VALUE:
      let newState=JSON.parse(JSON.stringify(state))
      newState[action.name]=action.value
      //setBookDataValid(newState)
      return newState
    case CLEAR_BOOK:
      return initialState
    default:
      return state
  }
}
export default book