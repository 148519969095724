import {
  SET_BOOKS,
  CREATE_BOOK,
  DELETE_BOOK,
  CLEAR_BOOKS,
} from '../actions'

const books = (state = [], action) => {
  switch(action.type) {
    case SET_BOOKS:
      return  action.books
    case CREATE_BOOK:
      return [...state, action.book]
    case DELETE_BOOK:
      return state.filter(event => event.id !== action.id)
    case CLEAR_BOOKS:
      return []
    default:
      return state
  }
}
export default books