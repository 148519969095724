import {
  CLEAR_HOST,
  SET_HOST,
} from '../actions'

export const initialState = {
  name: 'not login',
  uid: ''
}

const host = (state = initialState, action) => {
  switch(action.type) {
    case SET_HOST:
      return action.host
    case CLEAR_HOST:
      return  initialState
    default:
      return state
  }
}

export default host