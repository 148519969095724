import React, { useContext } from 'react'
//import 'date-fns';
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import AppContext from 'contexts/AppContext'

import { SET_BOOK_VALUE, } from 'actions'
import { changeBookProperty } from 'actions/book'
import { addBook } from 'actions/books'


const styles = {
    customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    }
};
const useStyles = makeStyles(styles);


export default function AddBook(props){
  const { state, dispatch } = useContext(AppContext)
  const classes = useStyles();

  const handleRoom = e => {
    console.log('AddBook.handleRoom', e)
    dispatch({
      type: SET_BOOK_VALUE,
      name: 'selectName',
      value: e.target.value
    })
  };

  //登録ボタン
  const handleRegister = () => {
    var index = state.rooms.findIndex(function(element) {
      return (element.number+'：'+element.name === state.book.selectName);
    });
    console.log('handleRegister', index, state)
    if (index < 0)	{
      alert('物件を選択してください');
       return; 
    }  //部屋が選択されてない
    var checkin = document.getElementById('checkin').value;
    var checkout = document.getElementById('checkout').value;
    if (state.book.nameState === "error") {
      alert('ゲスト名を入力してください');
      return; 
    }
    if (state.book.numState === "error") {
      alert('ゲスト数を入力してください');
      return; 
    }
    var book = {
      status: "initial",
      roomNumber: state.rooms[index].number,
      roomName: state.rooms[index].name,
      guestName: state.book.guestName,
      email: state.book.email,
      checkin: checkin,
      checkout: checkout,
      numOfGuest: state.book.numOfGuest
    }
    addBook(state.host, state.rooms[index], book, dispatch)
  }
  
  const listItems = state.rooms.map((room) => 
    <MenuItem　classes={{root: classes.selectMenuItem, 
        selected: classes.selectMenuItemSelected
      }}
      value={room.number+'：'+room.name}
      key={room.number}>
        {room.number+'：'+room.name}
      </MenuItem>
  )
  //console.log('AddBook', state, listItems)

  return(
    <GridContainer justify="center">
    <GridItem >
      <Card>
        <CardHeader color="rose" text>
          <CardText color="rose">
            <h4 className={classes.cardTitle}>予約の入力</h4>
						<div>
            　ゲスト名は、ゲスト様がサイトにログインしたときに表示されます。
            </div>
						<div>
            　メールアドレスは任意です。
						</div>
          </CardText>
        </CardHeader>
        <CardBody>
          <form>

            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel} >
                部屋選択
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={state.book.selectName}
                onChange={handleRoom}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
              >

              <MenuItem　disabled　classes={{root: classes.selectMenuItem}}>
                部屋を選択
              </MenuItem>
        
              {listItems}

              </Select>
            </FormControl>


            <CustomInput
              success={state.book.nameState === "success"}
              error={state.book.nameState === "error"}
              labelText="ゲスト名"
              id="guestName"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => {
                  changeBookProperty('guestName', event.target.value, dispatch)
                },
                type: "text",
                autoComplete: "off"
              }}
            />

            <CustomInput
              success={state.book.emailState === "success"}
              error={state.book.emailState === "error"}
              labelText="メールアドレス"
              id="guesterEmail"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => {
                  changeBookProperty('email', event.target.value, dispatch)
                },
                type: "email"
              }}
            />

              <TextField
                id="checkin"
                label="チェックイン"
                type="date"
                defaultValue=""
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="checkout"
                label="チェックアウト"
                type="date"
                defaultValue=""
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
        
            <CustomInput
              success={state.book.numState === "success"}
              error={state.book.numState === "error"}
              labelText="予約人数"
              id="numOfGguest"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => {
                  changeBookProperty('numOfGuest', event.target.value, dispatch)
                },
                type: "number",
                autoComplete: "off"
              }}
            />

            <Button
              color="success"
              onClick={handleRegister}
              className={classes.registerButton}
            >
              登録
            </Button>
          </form>
        </CardBody>
      </Card>
    </GridItem>
    </GridContainer>
  )
}
