import React, { useReducer, useEffect } from 'react'
import reducer from './reducers'
import firebase from './firebase'
import { createBrowserHistory } from "history"
import { Router, Route, Switch, Redirect } from "react-router-dom"

import AppContext from 'contexts/AppContext'

import AuthLayout from "layouts/Auth.js"
import RtlLayout from "layouts/RTL.js"
import AdminLayout from "layouts/Admin.js"
import { readHost } from './actions/host'
import { initialState as hostInitial } from 'reducers/host'
import { initialState as roomInitial } from 'reducers/room'
import { initialState as bookInitial } from 'reducers/book'

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0"

const hist = createBrowserHistory()

export const App = () => {
  const initialState = {
    host: hostInitial,
    room: roomInitial,
    rooms: [],
    book: bookInitial,
    books: [],
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    console.log('App.useEffect[]')
    firebase.auth().onAuthStateChanged((user) => {
      let uid = user ? user.uid : ''
      console.log("onAuthStateChanged", uid, state)
      readHost(uid, state.host, dispatch)
    })},[]
  )

  //console.log('App', state)
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Router history={hist}>
        <Switch>
        <Route path="/rtl" component={RtlLayout} />
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} />
      <Redirect from="/" to="/auth/about" />
        </Switch>
      </Router>
    </AppContext.Provider>
  )
}
export default hist
