import {
  SET_ROOM_VALUE,
} from '../actions'

export const changeRoomProperty = (name, value, dispatch) => {
  switch (name) {
    case 'number':
      if (verifyLength(value, 6)) {
        setState('numberState', 'success', dispatch)
      } else {
        setState('numberState', 'error', dispatch)
      }
      break;
    case 'name':
      if (verifyLength(value, 3)) {
        setState('nameState', 'success', dispatch)
      } else {
        setState('nameState', 'error', dispatch)
      }
      break;
    case 'capacity':
      if (verifyCapacity(value)) {
        setState('capacityState', 'success', dispatch)
      } else {
        setState('capacityState', 'error', dispatch)
      }
      break;
    default:
      break;
  }
  setState(name, value, dispatch)
}

// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true
  }
  return false
}

// 年齢を確認数値で、0から100までの範囲ならtrue
const verifyCapacity = value => {
  var numberRex = new RegExp("^[0-9]+$")
  if (numberRex.test(value)) {
    return Number(value) >= 1 && Number(value) <= 50
  }
  return false;
}


const setState = (name, value, dispatch) => {
  dispatch({
    type: SET_ROOM_VALUE,
    name: name,
    value: value
  })
}