import React, { useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import AppContext from 'contexts/AppContext'
import history from "App.js"
import { deleteRoom } from '../../actions/rooms'
import { setBook } from '../../actions/book'

const styles = {
    customCardContentClass: {
      paddingLeft: "0",
      paddingRight: "0"
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    }
};
const useStyles = makeStyles(styles);

export default function RoomList() {
  const { state, dispatch } = useContext(AppContext)
  const classes = useStyles();
  const style = {
      margin: 0,
      top: 'auto',
      right: 24,
      bottom: 24,
      left: 'auto',
      position: 'fixed',
  };
  //console.log('RoomList', state.rooms)

  function handleAdd(){
    history.push("/admin/add-room")
  }

  function handleList(room){
    //console.log('handleList', room)
    history.push('/admin/booklist')
  }

  function handleBook(room){
    //console.log('handleBook', room)
    let book = {
      roomNumber: room.number,
      roomName: room.name,
      guestName: '',
      email: '',
      numOfGuest: '',
      status: ''
    }
    setBook(book, dispatch)
    history.push('/admin/add-book')
  }

  const handleDelete = (number) => {
    const result = window.confirm(`物件：${number}を本当に削除しても良いですか？`)
    if (result) {
      deleteRoom(state, number, dispatch)
    }
  }
 
  var rooms = []
  state.rooms.map((room, index) => (
    rooms.push(
      [
        room.number,
        room.name,
        room.capacity,
        <Button color="info" size="sm" 
          onClick={handleList.bind(this, room)}>List</Button>,
        <Button color="success" size="sm"
          onClick={handleBook.bind(this, room)}>Book</Button>,
        <Button color="danger" size="sm"
          onClick={handleDelete.bind(this, room.number)}>Delete</Button>
      ]
    )
  ))

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <Card>
                <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Room List</h4>
                </CardHeader>
                <CardBody>
                    <Table tableHead7erColor="primary" 
                        tableHead={['登録番号', '名称', '定員', '予約一覧', '予約追加', '物件削除']} 
                        tableData={rooms}
                        />
                </CardBody>
            </Card>
        </GridItem>
      </GridContainer>
      <Button justIcon round 
          color="primary" 
          style={style}
          onClick = {handleAdd}
          >
          <AddIcon />
      </Button>
    </div>
  );
}