import firebase from 'firebase'
import history from 'App'
import errorLog from 'errorLog'


export const loginHost = (email, password) => {
  firebase.auth().signInWithEmailAndPassword(email, password)
  .then(function(result) {
    console.log('サインイン成功', {result})
//    logHost(host.uid, 'create-host', host)
    history.push('/admin/booklist/')
  }).catch(function(error) {
    console.error('サインイン失敗', {error})
    errorLog('host/sign-in', email, {error})
    alert(error.message)
  })
}


export const resetPassword = (email) => {
  firebase.auth().sendPasswordResetEmail(email)
  .then(function() {
    alert('パスワード再設定用のメールを送信しました')
    errorLog('host/sent-passwaord-reset-mail', email)
  }).catch(function(error) {
    console.error('パスワード再設定メール送信失敗', {error})
    errorLog('host/sent-passwaord-reset-mail-error', email, {error})
    alert(error.message)
  })
  history.push('/auth/about/')
}
