import firebase from '../firebase'
import { SET_HOST, CLEAR_HOST, CLEAR_ROOM, CLEAR_ROOMS, CLEAR_BOOK, CLEAR_BOOKS } from '../actions'
import { readRooms } from './rooms'
import { readBooks } from './books'
import errorLog from '../errorLog'


export const readHost = (hostID, host, dispatch) => {
  console.log('readHost', hostID, host)
  if (hostID === host.uid) {
    return
  }

  if (hostID.length < 1) {
    console.error('#####ここには来ないんじゃないのsign-out', host)
    logHost(hostID, 'sign-out', host)
    dispatch({type: CLEAR_HOST })
    return
  }

  firebase.firestore().collection("hosts").doc(hostID).get()
  .then(function(doc) {
    console.log('####host read from DB');
    if (!(doc.exists)) {
      console.log('####hostIDが存在しない:', hostID)
      dispatch({type: CLEAR_HOST})
    } else {
      console.log('####got_host')
      let host = doc.data()
      host.uid = hostID
      //console.log(host)
      logHost(hostID, 'sign-in', host)
      dispatch({type: SET_HOST, host: host})
      readRooms(dispatch)
      readBooks(dispatch)
    }
  })
  .catch(function(error) {
    console.error("####db読み込み失敗 ", error);
    errorLog('host/read-host', hostID)
    dispatch({type: CLEAR_HOST})
  })
}

export const logout = (host, dispatch) => {
  console.log('logout', host, dispatch)
  if (host.uid.length > 0) {
    logHost(host.uid, 'sign-out', host)
    firebase.auth().signOut()
    .then(function() {
      console.log('Sign-out successful.')
      dispatch({type: CLEAR_HOST })
      dispatch({type: CLEAR_ROOM })
      dispatch({type: CLEAR_ROOMS })
      dispatch({type: CLEAR_BOOK })
      dispatch({type: CLEAR_BOOKS })
    }).catch(function(error) {
      console.error('Sign-out error.', error)
      errorLog('host/sign-out', {error:error.code, host:host})
    });
  }
}

export const logHost = (hostid, event, content) => {
  console.log('host-log', hostid, event, content)
  let db = firebase.firestore()
  var log = {
    timestamp: firebase.firestore.Timestamp.now(),
    event: event,
    content: content
  }
  db.collection('hosts').doc(hostid).collection('logs').add(log)
  .then(function() {
  })
  .catch(function(error) {
    console.error('####guestlog書き込み失敗', error)
  })
}